// =============================================================================
//
//  Product Recommendations Desktop
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../../../settings';
@import '../../../mixins/flex';
@import '../../../mixins/media-queries';
@import '../../../typography/mixins';
@import '../../../themes/default/components/productRecommendations';

.product-recommendation {
    &-grid {
        @include flexbox;
        @include flex-flow(row wrap);
        margin: $product-recommendation-grid__margin--large;

        .product {
            width: $product-recommendation-product__width--large;
            padding: $product-recommendation-product__padding--large;
        }
    }
}

.my-account-section {
    .product-recommendation {
        &-calloutmsg {
            @include get-font('large');
            margin-bottom: 5rem;
        }

        .product {
            margin-bottom: 2rem;
        }
    }
}
