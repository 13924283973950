// =============================================================================
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

$newsletter__padding: $base-padding * 1.8;
$newsletter__padding-bottom: $base-padding;
$newsletter__background-color: $dark-red;
$newsletter-description-icon__height: 3rem;
$newsletter-description-icon__font-size: 3rem;
$newsletter-description-icon__color: #fff000;
$newsletter-description-title__margin-left: $base-margin;
$newsletter-description-title__font-weight: $bolder;
$newsletter-description-title__color: $white;
$newsletter-description-text__margin-top: $base-margin * 2.5;
$newsletter-input-container__margin-top: $base-margin * 2.3;
$newsletter-input-container__margin-right: $base-margin;
$newsletter-input-container__background-color: $white;
$newsletter-input__height: 100%;
$newsletter-input-container__height: 5rem;
$newsletter-input__padding: 0 ($base-padding * 1.3);
$newsletter-subscribe__margin-top: $base-margin * 1.8;
$newsletter-subscribe__height: 4rem;
$newsletter-form-subscribe__min-width: 10.6rem;

$newsletter-message__padding: $base-padding * 0.5 0;
$newsletter-success-message__color: $white;
$newsletter-error-message__color: $white;

$footer__padding: $base-padding * 1.8;
$footer__background-color: $black;
$footer__color: $white;

$footer-wrapper__padding: $base-padding * 1.8 $base-padding * 0;
$footer-wrapper__border-bottom: $border solid rgba($white, $alpha: 0.1);

$footer-cards-link__margin: 0 $base-margin * 1.2 $base-margin * 1.2 0;
$footer-cards-img__max-height: 3.5rem;
$footer-cards-img-klarna__max-height: 5rem;

$footer-wrapper-content-link__color: $white;
$footer-wrapper-content-link-active__color: $pf-light-grey;
$footer-wrapper-content__line-height: 2;
$footer-wrapper-title__color: $white;
$footer-wrapper-title__font-weight: $bolder;
$footer-wrapper-collapsible__border-bottom: $border solid rgba($white, $alpha: 0.1);

$footer-copyright__padding-top: $base-padding * 1.8;
$footer-copyright__color: $white;

$footer-logo__width: 10.3rem;
$footer-logo__height: 2.1rem;

$footer-logo-img__max-height: 2.3rem;

$footer-social-media-box-span__margin-right: $base-margin * 1.6;
$footer-social-media-box-span__color: rgba($white, $alpha: 0.6);
$footer-social-media-box-span__line-height: 1.71;
$footer-social-media-link-facebook__margin-right: $base-margin * 1.3;

$footer-flags__border-bottom: $border solid rgba($white, $alpha: 0.1);
$footer-flags-link__margin-right: $base-margin * 2;
$footer-flags-link__padding-botom: $base-padding * 1.8;
$footer-flags-img__height: 2rem;
$footer-flags-link-active__border-bottom: $border * 3 solid $white;

$footer-social-media-link__width: 2.4rem;
$footer-social-media-link__height: 2.4rem;
$footer-social-media-link__color: $white;
$footer-social-media-link__font-size: 2.4rem;

//-----------------------------------------------------------------------------
// Screen Large
// -----------------------------------------------------------------------------

$footer__padding-top--large: $base-padding * 3;
$footer__padding-bottom--large: $base-padding * 4.2;

$newsletter__padding--large: $base-padding * 3.1;
$newsletter__padding-bottom--large: $base-padding * 4.1;
$newsletter-description__flex-basis--large: 39%;
$newsletter-input-container__flex-basis--large: 36%;
$newsletter-input__height--large: 6rem;
$newsletter-subscribe__flex-basis--large: 23%;
$newsletter-subscribe__padding--large: ($base-padding * 1.65) ($base-padding * 5.6);
$newsletter-subscribe__margin-left--large: $base-margin * 1.6;
$newsletter-subscribe__height--large: 6rem;
$newsletter-subscribe__max-width: 10rem;
$newsletter-description-text__margin-top--large: $base-margin * 2.6;
$newsletter-description-text__padding-right--large: $base-padding * 5;
$newsletter-form-input__margin-right--large: $base-margin * 1.5;
$newsletter-bottom__margin-top--large: $base-margin * 1.8;

$footer-wrapper-content-link__color--large: $white;
$footer-wrapper__padding-top--large: $base-padding * 1.8;
$footer-wrapper-collapsible__padding-bottom--large: $base-padding * 1.8;
$footer-wrapper__flags__border-bottom--large: $border solid rgba($white, $alpha: 0.1);
$footer-wrapper-collapsible__max-width: 31rem;
$footer-wrapper-collapsible__width: 100%;
$footer-wrapper-collapsible__margin-right: $base-margin * 2;

$footer-cards-link__margin-right--large: $base-margin * 1.2;

$footer-copyright__color--large: rgba($white, $alpha: 0.6);

$footer-wrapper-content__line-height--large: 1.9;
$footer-wrapper-content__padding-right--large: $base-padding * 2;
$footer-wrapper-title__padding-right--large: $base-padding * 2;
$footer-wrapper-title__font-weight--large: 700;
