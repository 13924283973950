// =============================================================================
//
//  Header Main Large Screens
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width-x-large) {
    .header {
        &.scroll-down {
            top: -($header-main__height--large + $header-top__height--large + $header-navigation__height--large);
        }

        .header-site-search {
            padding: 0;
            background: $red;
        }

        &.sticky {
            .header-top {
                display: none;
            }

            .header-logo-image {
                display: block;
                height: $logo-sticky__height;
                max-height: $logo-sticky__height;
            }

            .header-search-form,
            .header-site-search,
            .header-container-search {
                position: static;
            }

            .header-site-search {
                padding: 0;
                background: $red;
            }


            .header-search-dropdown {
                top: $sticky-header-header-search-top--large;
                left: $sticky-header-header-search-dropdown__left--large;
                width: $sticky-header-header-search-dropdown__width--large;
                margin-left: 0;
                text-align: left;
                transform: translateX(-50%);
            }

            // stylelint-disable selector-class-pattern

            .header-main,
            .header-container-search,
            .menu-level-1-wrapper {
                height: $header-sticky__height--large;
            }

            .header-search-form input[type='search'] {
                height: $header-sticky-input-search__height--large;
            }

            .header-sticky-logo,
            .header-sticky-icons {
                display: block !important;
            }

            .header-sticky-icons {
                position: relative;
            }

            .header-logo-big {
                height: $logo-big-sticky__height;
            }

            .header-logo-image {
                margin-top: $base-margin * -0.5;
            }

            .header-container-search .icon-search {
                top: $header-search-icon-sticky__top--large;
                left: auto;
                padding-left: $header-search-icon-sticky__padding-left--large;
                font-size: $header-search-icon-sticky__font-size--large;
            }

            .menu-level-2-container {
                top: $header-menu-level-two-sticky__top--large;
            }

        }

        &-main {
            height: $header-main__height--large;
        }

        &-main-container {
            @include flexbox();
            @include align-items(center);
            padding: 0;
        }

        &-menu-icons {
            width: $menu-icons__width--large;
            margin-left: auto;
        }

        &-logo-big {
            margin-right: auto;
        }

        &-logo-link {
            @include flexbox();
        }

        &-logo-image {
            max-height: $logo-big__height;
        }

        &-user-icon {
            height: $header-main-user-icon__height--large;

            .icon-nowishlist {
                top: $header-main-wishlist-icon__top--large;
                left: $header-main-wishlist-icon__left--large;
                width: $header-main-wishlist-icon__width--large;
                height: $header-main-wishlist-icon__height--large;
                font-size: $header-main-wishlist-icon__font-size--large;
                line-height: $header-main-wishlist-icon__line-height--large;
                text-align: center;

                &::before {
                    line-height: $header-main-wishlist-icon__line-height--large;
                }
            }
        }

        &-login-link {
            font-size: $header-main-login-icon__font-size--large;
        }

        &-cart-icon {
            @include align-items(center);
            height: $header-main-cart-icon__height--large;
            margin-left: $header-main-icons-side__margin--large;
            font-size: $header-main-cart-icon__font-size--large;
        }

        &-container-search {
            position: relative;
            max-width: $header-search-container__width--large;
            height: $header-search__height--large;
            flex: 1;
            margin: 0 auto;
            padding: $header-search-container__padding;

            .icon-search {
                top: 1.8rem;
                font-size: $header-search-icon__font-size--large;
            }
        }

        &-search-form {
            height: auto;
            padding: 0;

            input[type='search'] {
                @include get-font('normal');
                width: $header-search-input__width--large;
                height: $header-search__height--large;
                border-radius: 0;
                margin-left: $header-search-form__margin-left--large;

                &::placeholder {
                    font-size: $header-search-placeholder__font-size--large;
                }
            }
        }

        &-menu-container {
            position: relative;
            background-color: $header-navigation__background-color--large;

            .wrapper {
                position: static;
                padding: $header-navigation-second-level-wrapper__padding--large;
            }
        }
    }
}
