// =============================================================================
//
//  Ribbon Desktop
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../../../themes/default/components/ribbon';

.promotions {
    .promotion {
        @include get-font('almost-big');
        min-width: $promotion__min-width--large;
        padding: $promotion__padding--large;

        &::after {
            border-width: $promotion-after__border-width--large;
            border-top-color: $black;
            margin-bottom: $promotion-after__margin-bottom--large;
        }
    }
}

.promotions-right {
    .promotion {
        &::after {
            display: none;
        }
    }
}
