// =============================================================================
//
//  Inputs Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$input__padding: ($base-padding * 1.65) ($base-padding * 1.8);
$input__border: $black-two solid $border;

$input-active__box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.5);

$input-active-is-invalid__border-color: $red;

$input-disabled__border-bottom-color: $black;

$input-is-invalid__border-color: $red;

$select-dropdown-icon__top: 4.3rem;
$select-dropdown-icon__font-size: 1.6rem;

$input-checkbox-before__width: 2rem;
$input-checkbox-before__height: 2rem;
$input-checkbox-before__border: $brown-grey solid $border;

$input-checkbox-icon__top: 0.5rem;
$input-checkbox-icon__left: 0.5rem;
$input-checkbox-icon__color: $white;
$input-checkbox-icon__font-size: 1rem;

$input-checkbox-icon-before__background-color: $pf-grey;

$input-checkbox-disabled-before__opacity: 0.5;

$input-radio-before__width: 2.2rem;
$input-radio-before__height: 2.2rem;
$input-radio-before__border: $pf-grey solid $border;
$input-radio-before__background-color: $white;
$input-radio-before__color: $pf-grey;
$input-radio-before__line-height: 2rem;

$input-radio-checked-before__border: $red solid $border;
$input-radio-checked-before__background-color: $red;
$input-radio-checked-before__box-shadow: inset 0 0 0 0.4rem $white;

$input-radio-disabled-before__opacity: 0.5;

$quantity__width: 4rem;
$quantity__height: 4rem;
$quantity__padding: $base-padding * 0.6;
$quantity__border: ($border * 0.5) solid $pf-grey;
$quantity__margin: 0 ($base-margin * 0.8);
$quantity__color: $black;
$quantity__font-weight: $bold;

$quantity-icon__padding: ($base-padding * 0.7) 0;
$quantity-icon__color: $white;
$quantity-icon__font-size: 1.4rem;

$quantity-icon-before__padding: $base-padding * 0.6;
$quantity-icon-before__background-color: $pf-grey;

$quantity-icon-icon-disabled__opacity: 0.1;
$quantity-icon-hover-before: $red;

// =============================================================================
//  Large Screen
// =============================================================================

$quantity__width--large: 4rem;
$quantity__height--large: 4rem;
$quantity__padding--large: ($base-padding * 0.9);

$quantity-icon__padding--large: ($base-padding * 1.2) 0;
$quantity-icon__font-size--large: 1.6rem;

$quantity-icon-before__padding--large: $base-padding * 0.7;
