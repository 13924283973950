// =============================================================================
//
//  Header Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// General
$header__background-color: $white;
$header__transition: top 0.35s linear;
$header-menu__transition: left 0.3s ease;
$header-links__transition: color 0.3s ease;
$header-elements-full__width: 100%;
$header-elements-full__height: 100%;
$header-elements-base__padding: $base-padding * 1.8;
$header-elements-base__padding--medium-large: $base-padding * 0.4;

// Header Top Variables

$header-top__height: $header-top__height;
$header-top__background-color: $blue;
$header-top-icons__margin: ($base-margin * 0.1) ($base-margin * 0.7) 0 0;
$header-top-icons__margin-right: $base-margin;
$header-top-icons__color: $white;
$header-top-text__color: $white;
$header-top-link-name-hover__color: $white;
$header-top-link-name__border-bottom: 0.2rem solid transparent;
$header-top-link-name-hover__border-color: $header-top-link-name-hover__color;
$header-top-return-con__line-height: 1;

$header-top__height--large: $header-top__height--large;
$header-top-icons__margin-right--large: $base-margin * 2.5;

// Header Main Section Variables

$header-main__height: $header-main__height;
$header-main__margin-bottom: $base-margin * 5;
$header-main__background-color: $header-bg;
$header-main-menu-icons__color: $white;
$header-main-icons-side__margin: $base-margin * 1.5;
$header-cart-icon-side__margin-left: $base-margin * 1.5;
$header-main-container__padding: ($base-padding * 1.8) 0;
$header-main-container__background-color: transparent;
$header-main-hamburger-icon__font-size: 2.3rem;
$header-main-hamburger-icon__height: $header-main-hamburger-icon__font-size;
$header-main-login-icon__font-size: 1.9rem;
$header-main-cart-icon__font-size: 1.9rem;
$header-main-icons-hover__color: $header-top-link-name-hover__color;
$header-main-user-icon__height: 1.9rem;
$header-main-cart-icon__height: 1.9rem;

$header-main-icons-side__margin--large: $base-margin * 2;
$header-main-login-icon__font-size--large: 2.6rem;
$header-main-cart-icon__font-size--large: 2.6rem;
$header-main-user-icon__height--large: 3rem;
$header-main-cart-icon__height--large: 3.3rem;
$menu-icons__width--large: 21rem;

$header-main-wishlist-icon__top: -0.6rem;
$header-main-wishlist-icon__left: 1.1rem;
$header-main-wishlist-icon__color: $red;
$header-main-wishlist-icon__background-color: $white;
$header-main-wishlist-icon__font-size: 1rem;
$header-main-wishlist-icon__line-height: 1;
$header-main-wishlist-icon__height: 1.9rem;
$header-main-wishlist-icon__width: 1.9rem;

$header-main-wishlist-icon__top--large: -0.4rem;
$header-main-wishlist-icon__left--large: 1.5rem;
$header-main-wishlist-icon__font-size--large: 1.1rem;
$header-main-wishlist-icon__line-height--large: 1;
$header-main-wishlist-icon__height--large: 2rem;
$header-main-wishlist-icon__width--large: 2rem;

// Logo

$logo-small__width: 16rem;
$logo-small__height: 2.2rem;
$logo-big__width: 24rem;
$logo-big__height: 4.5rem;
$logo-sticky__height: 3.2rem;

// Header Search

$header-search-form__height: $header-main__height;
$header-search__height: $header-main__height;
$header-search-input__background-color: $white;
$header-search-input__margin-left: $base-margin * 0.6;
$header-search-input__width: 100%;
$header-search-form__padding: 0 ($base-padding * 0.6);
$header-search-form__text-align: center;
$header-search__background-color: $header-bg;
$header-search-input__border-color: $header-bg;
$header-search-placeholder__color: $greyish-brown-two;
$header-search-icon__font-size: 2.2rem;
$icon-search__left: 1.8rem;
$icon-search__top: 0.8rem;
$icon-search__top--large: 1rem;
$icon-search__position: absolute;

$header-search-icon__font-size--large: 4.1rem;
$header-search__height--large: $header-main__height--large;
$header-search-placeholder__font-size: 1.6rem;
$header-search-placeholder__font-size--large: 2.5rem;

$header-search-container__width--large: 71rem;
$header-search-container__padding: 0 1.5rem;
$input__border-bottom: $dark-red solid ($border * 3);

// Header Sticky Variables

$header-search-icon-sticky__font-size--large: 2.6rem;
$header-search-icon-sticky__padding-left--large: $base-padding * 1.2;
$header-search-icon-sticky__top--large: 1rem;
$logo-big-sticky__height: 2rem;
$header-sticky__height--large: 5rem;
$header-sticky-input-search__height--large: 5rem;
$header-container-search-sticky__width--large: 94rem;
$header-container-search-sticky__width--medium-large: 60rem;
$header-menu-level-two-sticky__top--large: $header-sticky__height--large;
$header-sticky-icons__top--large: 0.6rem;

// Header Navigation Variables

$header-navigation__background-color: $header-bg;
$header-navigation-pages-container__margin: ($base-margin * 4.8) 0 ($base-margin * 5.5);
$header-navigation-close-icon__color: $white;
$header-navigation-back-icon__font-size: 1.6rem;
$header-navigation-back-icon__color: $white;
$header-navigation-back-text__margin-left: $base-margin;
$header-navigation-back-text__line-height: 2rem;
$header-navigation-back-text_flex: 0 0 33%;
$header-navigation-back-action__margin-top: $base-margin * 1.5;
$header-navigation-links__color: $white;
$header-navigation-separator__background-color--large: #b8b8b8;
$header-navigation-icon-arrow__color: $white;
$header-navigation__border-color-light: $pf-light-grey;
$header-navigation-border__height: 0.2rem;
$header-navigation-first-categpry-level-link__font-weight: $bolder;
$header-navigation-action__padding-bottom: $base-padding * 2;
$header-navigation-second-level-labels__color: $pf-light-grey;
$header-navigation-dropdown-content__margin: ($base-margin * 6.5) 0 ($base-margin * 5.5);
$header-navigation-icon-close__font-size: 1.9rem;
$header-navigation-link-icon-arrow-right__height: 1.6rem;
$header-navigation-menu-parent-category-position__top: -4rem;
$header-navigation-link__font-size: 1.6rem;
$header-navigation-link__text-decoration: underline;
$header-navigation-state-trigger__text-transform: uppercase;
$header-navigation-item-border__background-color--large: $header-bg;
$header-navigation-second-level-columns__padding--large: 0 ($base-padding * 1.25);
$header-navigation-first-categpry-level-link__font-weight--large: 400;
$header-navigation-first-categpry-level-link-bold__font-weight--large: $bolder;
$header-navigation-item-hover__color--large: $white;
$header-navigation-item-hover__background-color--large: $dark-red;
$header-navigation-dropdown__background-color--large: $black;
$header-navigation-view-brands-page__color--large: $header-bg;
$header-navigation-second-level-inner__padding--large: ($base-padding * 2.8) ($base-padding * 6.8);
$header-navigation-second-level-inner__padding--medium-large: 0 ($base-padding * 6.8);
$header-navigation-second-level-links__border-color--large: $black;
$header-navigation-second-level-links__border--large: $border solid $header-navigation-second-level-links__border-color--large;
$header-navigation__height--large: $header-navigation__height--large;
$header-navigation-view-brands-page__padding-top--large: $base-padding;
$header-navigation-second-level-links__padding--large: ($base-padding * 1.3) 0;
$header-navigation-second-level-links__padding--medium-large: $base-padding 0;
$header-navigation-second-level-links__color--large: $white;
$header-navigation-second-level-labels__border-color--large: $black;
$header-navigation-second-level-links__font-weight--large: $bold;
$header-navigation__background-color--large: $menu-bg;
$header-navigation-border__height--large: 0.4rem;
$header-navigation-second-level-wrapper__padding--large: $wrapper-header__padding--large;
$header-navigation-second-level-wrapper__padding--medium-large: $wrapper-header__padding--medium-large;
$header-navigation-link__font-size--large: 1.4rem;
$header-navigation-link__line-height--large: 1.4rem;
$header-navigation-category-link__font-size--large: 1.8rem;
$header-navigation-category-link__font-size--medium-large: 1.5rem;
$header-navigation-category-link__font-size-small--medium-large: 1.3rem;
$header-navigation-subcategory-link__font-size--large: 1.7rem;
$header-navigation-subcategory-link__font-size--medium-large: 1.6rem;
$header-navigation-page-list__font-size--large: 1.4rem;
$header-navigation-dropdown-labels__font-size: 1.4rem;
$header-navigation-page-list__margin-left--large: $base-margin * 3;
$header-navigation-main-container__background-color--large: transparent;
$header-navigation-border__transition--large: background-color 0.3s ease;
$header-navigation-height__transition--large: height 0.3s ease;
$header-navigation-view-brands__text-decoration--large: underline;
$header-navigation-content-block-small__width--large: $header-elements-full__width / 3;
$header-navigation-dropdown-labels__padding--large: $header-elements-base__padding 0 ($header-elements-base__padding * 0.67);
$header-navigation-dropdown-labels__padding--medium-large: $header-elements-base__padding 0 $header-elements-base__padding;
$header-navigation-blocks__flex--large: 0 0 $header-elements-full__width / 4;
$header-navigation-menu-content-block__flex--large: 0 0 $header-elements-full__width / 2;
$header-navigation-not-first-content-block__flex--large: 1;
$header-navigation-first-content-block__padding-bottom--large: $base-padding * 2.6;
$header-navigation-second-level-blocks__padding-left--large: $base-padding * 1.3;
$header-navigation-second-level-blocks__padding-right--large: $base-padding * 1.3;
$header-navigation-right-section-separator__width--large: 0.1rem;
$header-navigation-right-section-separator__height--large: 2.4rem;
$header-navigation-right-section-separator__top--large: $header-elements-full__height / 2;
$header-navigation-open-overlay__width--large: 100vw;
$header-navigation-open-overlay__height--large: 100vh;
$header-navigation-open-overlay__background-color--large: rgba($black-two, 0.6);


$header-search-input__width--large: 100%;
$header-search-form__margin-left--large: 0;

$sticky-header-header-search-dropdown__left--medium--large: 2.5rem;
$sticky-header-header-search-dropdown__width--medium--large: calc(100% - 5rem);

$sticky-header-header-search-top--large: 5rem;
$sticky-header-header-search-dropdown__left--large: 50%;
$sticky-header-header-search-dropdown__width--large: 104.3rem;


$header-top__background-color--large: $header-bg;

$header-menu-category-level-2__height--medium-large: 33rem;
$header-menu-category-level-2__height--large: 36rem;
