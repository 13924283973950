// =============================================================================
//
//  Product Tile Large
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../../../themes/default/components/productTiles';

@include media-screen-lg {
    .product {
        border: none;
        margin-bottom: $product__margin-bottom--large;

    }

    .promotion-wrapper {
        position: absolute;
        z-index: 5;
        top: 0;
        left: $product-tile-promotion-wrapper__left--large;

    }

    .product-tile {
        @include flex-flow(column nowrap);
        position: relative;
        height: 100%;
        padding: $product-tile__padding--large;
        border-bottom: $border solid $very-light-pink;
        border-left: $border solid $very-light-pink;

        &-promotions-right {
            margin-bottom: 0;

            .promotions-right {
                z-index: 5;

                &.promotions-sale {
                    top: $product-tile-promotions-sale__top--large;
                }
            }

            .promotion {
                @include get-font('tiny');
                display: $product-tile-promotion-wrapper-promotion__display;
                width: $product-tile-promotion-wrapper-promotion__width;
                min-width: $product-tile-promotion__min-width--large;
                padding: $product-tile-promotion__padding--large;
                margin: 0;

                &.sale {
                    background: $red;
                    color: $white;
                }

                &.custom {
                    background: $black;
                    color: $white;
                }

                &.new {
                    margin-left: 0;
                    background: $light-blue;
                    color: $white;
                }

                &:last-child {
                    border: none;
                }
            }
        }

        &-is-eligible-for-pledge-wrapper {
            position: absolute;
            bottom: 0.2rem;
        }

        &-rating {
            width: 10rem;
            height: 2.5rem;
            order: -1;
            margin-top: 0;

            .gmf-rating {
                margin-left: -0.3 * $base-margin;
            }
        }

        &-image__container {
            width: 100%;
        }

        &-image__container-item {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }

        &-image {
            width: 100%;
            height: auto;
        }

        &-description,
        &-CTA {
            padding-top: 0;
        }

        &-description {
            margin: $product-tile-description__margin--large;
        }

        &-description-top {
            @include flexbox();
            @include justify-content(space-between);

            .link {
                width: calc(100% - 4rem);
            }
        }

        &-top-rating-variant {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
            order: -1;
            margin: $base-margin 0;
        }

        &-num-of-variants-wrapper {
            order: 0;
            padding: ($base-padding * 0.2) ($base-padding * 0.4);
            margin: 0;
        }

        &-description-bottom {
            display: block;
        }

        &-name {
            line-height: $product-tile-name__line-height--large;
        }

        &-brand {
            @include get-font('xsmall');
            margin-top: unset;
        }

        &-CTA {
            &:not(.minicart-product-tile-price) {
                @include flex-flow(row wrap);
                @include align-items(flex-start);
                margin-top: $product-tile-cta__margin-top--large;

                .price {
                    text-align: left;

                    .price-adjusted {
                        @include get-font('large');
                        color: $red;
                        line-height: 0.7;
                    }

                    .price-non-adjusted {
                        @include get-font('xsmall');
                        width: 100%;
                    }

                    .price-sales {
                        @include get-font('large');
                        line-height: 0.7;
                    }
                }

                .price-ref-wrapper {
                    width: 100%;
                    height: $price-ref__min-height;
                    margin-top: $price-ref__margin-top--large;
                }

                .price-ref {
                    @include get-font('mini');
                    @include justify-content(flex-start);
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .product-tile-buy {
                    @include get-font('xsmall');
                    padding: $product-tile-buy__padding--large;
                }
            }
        }

        .wishlist-wrapper {
            right: 0rem;
        }
    }

    .cart {
        .product-tile-CTA { // stylelint-disable-line selector-class-pattern
            .price-adjusted {
                @include get-font('medium-big');
            }

            .price-sales {
                @include get-font('medium-big');
            }
        }
    }
}

@supports (-ms-ime-align: auto) {
    .product-tile {
        &-image__container {
            height: auto;
            padding-bottom: 0;
        }
    }
}
