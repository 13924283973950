// =============================================================================
//
//  @version    0.0.1
//  @package    Minicart Bodystore
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@import '../../../../themes/default/components/minicart/miniCart';

.minicart-total-saving {
    @include get-font('small');
}

.cart-delete-confirmation-btn {
    @include get-font('xsmall');
}

.added-to-cart-box {
    width: $added-to-cart-box__width--large;
}

.minicart {
    .product-tile {
        &.bonus-tile-product {
            padding-top: $base-padding;
        }
    }

    &-content {
        .product {
            margin-bottom: $minicart-content-product__margin-bottom--large;
        }
    }

    &-quantity {
        @include get-font('mini');
        top: $minicart-quantity__top--large;
        left: $minicart-quantity__left--large;
        width: $minicart-quantity__width--large;
        height: $minicart-quantity__height--large;
    }

    &-wrapper {
        max-width: $minicart-wrapper__max-width--large;
    }

    &-products {
        .product-tile-description {
            margin: $minicart-products-product-tile-description__margin--large;
        }

        .product-tile {
            @include flex-flow(row);
            border-bottom: $minicart-products-product-tile-__border-bottom--large;
            border-left: none;
        }

        .product {
            &:last-of-type {
                .product-line-item {
                    border-bottom: none;
                }
            }
        }
    }

    &-content-title {
        @include get-font('large');
    }

    &-footer {
        padding: $minicart-footer__padding--large;

        &-bottom {
            padding-top: $minicart-footer-bottom__padding-top--large;
        }
    }
}

.sub-total-label {
    @include get-font('small');
    margin-bottom: $sub-total-label__margin-bottom;
}

.sub-total {
    @include get-font('large-xlarge');
}
