// =============================================================================
//
//  @version    0.0.1
//  @package    Gymgrossisten
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

// stylelint-disable selector-class-pattern


.gamifiera-tutorial {
    margin-bottom: $base-margin * 3;

    .border-left {
        padding-bottom: $base-padding * 3;
        border-left: $border solid $very-light-pink;
    }

    &-item {
        @include flex-wrap(nowrap);
        padding-top: $base-padding * 3;

        &:first-child {
            padding-bottom: 0;
            border-top: $border solid $very-light-pink;
        }

        .gamifiera-tutorial-item-half {
            width: 50%;
            padding-left: $base-padding * 2.2;

            &:first-child {
                padding-left: 0;
                margin-right: $base-padding * 2.2;
            }


            &.padd0 {
                border-bottom: $border solid $very-light-pink;
            }
        }

        p {
            @include get-font('small');
        }

        .gamifiera-link {
            @include get-font('small');
        }

        &-image {
            margin-left: auto;
        }
    }

}

.gmf-leaderboard {
    width: 49.6rem !important;
}

.product-tabs {
    .gmf-comment-content-col {
        margin-right: $base-margin * 20.5;
    }
}
