// =============================================================================
//
//  Header Search large screen
//
//  @version    0.0.1
//  @package    HSNG
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../../../themes/default/components/headerSearch';

.clear-search {
    top: 50%;
    transform: translateY(-50%);
    right: $clear-search__right--large;
}

.popular-search-dropdown {
    &-links {
        padding-bottom: $popular-search-dropdown-links__padding-bottom--large;
    }

    &-title {
        @include get-font('xsmall');
        text-transform: none;
    }

    &-list {
        &-li {
            @include flex(none);
            margin-right: $popular-search-dropdown-list-li__margin-right--large;

            >.suggestion-item {
                @include get-font('small');
            }
        }
    }

    &-help {
        &-title {
            @include get-font('xsmall');
            text-transform: none;
        }

        &-list {
            &-li {
                padding-bottom: $popular-search-dropdown-help-list-li__padding-bottom--large;

                >.suggestion-item {
                    @include get-font('small');
                    border-bottom: none;
                    text-decoration: none;
                }
            }
        }
    }
}

.header-search-dropdown {
    max-height: calc(100vh - 15.5rem);
    position: $header-search-dropdown__position--large;
    z-index: $header-search-dropdown__z-index--large;
    top: $header-search-dropdown__top--large;
    left: $header-search-dropdown__left--large;
    width: $header-search-dropdown__width--large;
    padding: $header-search-dropdown__padding--large;
    margin: 0;
    margin-left: $header-search-dropdown__margin-left--large;

    &-results {
        @include flex-flow(row nowrap);
    }

    &.suggestions {
        margin-top: 0;
    }

    &-title {
        @include get-font('xsmall');
        margin-bottom: $header-search-dropdown-title__margin-bottom--large;
        text-transform: none;

        .view-more-products-link {
            display: none;
        }
    }

    .header-search-dropdown-products-description {
        .add-to-basket {
            @include get-font('small');
            padding: $add-to-basket__padding--large;
        }
    }

    &-categories {
        flex: $header-search-dropdown-categories__flex--large;

        &-title {
            @include get-font('xsmall');
            text-transform: none;
        }

        &-item {
            padding: $header-search-dropdown-categories-item__padding--large;

            &.first-category-item {
                padding-top: 0;
                border-top: none;
            }
        }

        &-results-list {
            &-li {
                line-height: $header-search-dropdown-categories-results-list-li__line-height--large;
            }
        }
    }

    &-products {
        margin-left: $header-search-dropdown-products__margin-left--large;

        .view-more-products {
            padding: 0;
        }
    }


    &-products-wrapper {
        @include flex-wrap(wrap);
        @include flexbox();
        margin: 0 ($base-margin * -1);

        >.header-search-dropdown-product {
            @include flex(0 1 33.3333333%);
            padding: $header-search-dropdown-product__padding--large;

            &:first-of-type {
                .product {
                    border-top: none;
                }
            }

            .product {
                height: 100%;
                padding-bottom: $header-search-dropdown-product-tile__padding-bottom--large;
                border-bottom: none;
            }

            .product-tile {
                &-CTA {
                    @include flex(0);
                }

                &-promotions-right {
                    margin-bottom: 0;
                }

                &-description {
                    @include flex-grow(1);
                }
            }
        }
    }

    &-categories-results-list-li-link {
        text-decoration: none;
    }
}

.header-search-dropdown-categories-results {
    &-paragraph {
        >.results-link {
            text-decoration: none;

            >.name {
                font-weight: normal;
            }
        }
    }
}


@media screen and (min-width: $desktop-width) and (max-width: 1350px) {
    .header-search-dropdown-products-wrapper>.header-search-dropdown-product {
        @include flex(0 0 33.333%);
    }

    .products-grid-wrapper .product {
        @include flex(0 0 33.333%);
        width: 33.333%;
    }
}

@media screen and (min-width: $desktop-width) and (max-width: 1050px) {
    .product-tile {
        &-CTA {
            .price .price-sales {
                font-size: 2.3rem;
            }
        }
    }
}

// stylelint-disable selector-class-pattern
// stylelint-disable selector-max-compound-selectors

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        .header-search-dropdown-products-wrapper>.header-search-dropdown-product .product-tile-CTA {
            flex: 1;
        }
    }
}
