// =============================================================================
//
//  Header Search variables
//
//  @version    0.0.1
//  @package    HSNG
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

$header-search__position: relative;

$clear-search__position: absolute;
$clear-search__top: 0;
$clear-search__right: 0;
$clear-search__width: 5rem;
$clear-search__height: 5rem;
$clear-search__background-color: #d8d8d8;
$clear-search__border-radius: 50%;
$clear-search-close__height: 1.8rem;
$clear-search-close__font-size: 1.8rem;

$view-more-products__width: 100%;
$view-more-products__padding: ($base-padding * 2) 0;
$view-more-products__text-align: center;

$view-more-products-link__color: $greyish-brown;
$view-more-products-link__font-weight: $bold;
$view-more-products-link__line-height: 2.5;
$view-more-products-link__text-decoration: underline;
$view-more-products-link__text-decoration-underline-color: $very-light-pink;

$header-search-dropdown__padding: $base-padding * 2 $base-padding * 1.2;
$header-search-dropdown__margin: $base-margin * 0.8;
$header-search-dropdown__background: $dropdown-search-bg;
$header-search-dropdown__box-shadow: 0 0.2rem 0.8rem 0 rgba($black, 0.5);

$header-search-dropdown-categories__width: 100%;

$header-search-dropdown-categories-results-list-li__margin-bottom: $base-margin * 0.5;
$header-search-dropdown-categories-results-list-li__line-height: 2.14;

$header-search-dropdown-categories-results-list-li-link__color: $black;
$header-search-dropdown-categories-results-list-li-link__font-weight: $bolder;

$header-search-dropdown-categories-title__margin-bottom: $base-margin * 1;
$header-search-dropdown-categories-title__color: $brown-grey;
$header-search-dropdown-categories-title__font-weight: $bold;

$header-search-dropdown-products__width: 100%;

$header-search-dropdown-products-wrapper-product-tile__border-bottom: $border solid $pale-liliac;

$header-search-dropdown-title__color: $brown-grey;
$header-search-dropdown-title__text-transform: uppercase;
$header-search-dropdown-title__font-weight: $bold;
$header-search-dropdown-title__margin-bottom: $base-margin * 1.5;

$header-search-dropdown-categories-item__padding: $base-padding * 1.5 0;
$header-search-dropdown-categories-item__border-top: $border solid $pale-liliac;

$header-search-dropdown-categories-item-paragraph__color: $black;
$header-search-dropdown-categories-item-paragraph__font-weight: $bold;
$header-search-dropdown-categories-item-paragraph__text-decoration: underline;

$header-search-dropdown-categories-item-paragraph-link__color: $black;
$header-search-dropdown-categories-item-paragraph-link__font-weight: $bold;

$header-search-dropdown-categories-results-paragraph__margin: $base-margin 0 0 0;
$results-link__font-weight: $bolder;

$rabat__margin-left: $base-margin * 0.3;
$rabat__color: $red;

$header-search-dropdown-products-description__width: calc(100% - 9.2rem);
$header-search-dropdown-products-description__margin-left: $base-margin;
$header-search-dropdown-products-description__position: relative;

$header-search-dropdown-products-description-top-seller__color: $black;

$price__position: absolute;
$price__top: 0;
$price__right: 0;
$price__color: $red;
$price__font-weight: $bold;

$price-old__color: $brown-grey;
$price-old__text-align: right;
$price-old__line-height: 1.71;
$price-old__text-decoration: line-through;

$header-search-dropdown-products-description-title__max-width: calc(100% - 10rem);
$header-search-dropdown-products-description-title__margin: $base-margin * 0.8 0 $base-margin * 0.14 0;
$header-search-dropdown-products-description-title__color: $black;
$header-search-dropdown-products-description-title__font-weight: $bold;
$header-search-dropdown-products-description-title__line-height: 1.2;

$header-search-dropdown-products-description-category__color: rgba($black, 0.5);
$header-search-dropdown-products-description-category__font-size: 1.1rem;
$header-search-dropdown-products-description-category__line-height: 1.09;

$add-to-basket__position: absolute;
$add-to-basket__bottom: 0;
$add-to-basket__right: 0;

$popular-search-dropdown__width: 100%;

$popular-search-dropdown-links__border-bottom: $border solid rgba($black, 0.2);
$popular-search-dropdown-title__margin-bottom: $base-margin * 2;
$popular-search-dropdown-title__color: $dropdown-search-color;
$popular-search-dropdown-title__font-weight: $bold;
$popular-search-dropdown-title__text-transform: uppercase;
$popular-search-dropdown-list-li__padding-bottom: $base-padding * 1.5;

$popular-search-dropdown-suggestion-item__border-bottom: $border solid $light-grey;
$popular-search-dropdown-suggestion-item__color: $dropdown-search-color;
$popular-search-dropdown-suggestion-item__font-weight: $bolder;
$popular-search-dropdown-suggestion-item__padding-bottom: $base-padding * 0.2;

$popular-search-dropdown-help__padding-top: $base-padding * 1.5;
$popular-search-dropdown-help-title__margin-bottom: $base-margin * 1.5;
$popular-search-dropdown-help-title__color: $dropdown-search-color;
$popular-search-dropdown-help-title__font-weight: $bold;
$popular-search-dropdown-help-title__text-transform: uppercase;

$popular-search-dropdown-help-list-li__padding-bottom: $base-padding;
$popular-search-dropdown-suggestion-item__line-height: 1.5;

//-----------------------------------------------------------------------------
// Screen Medium
// -----------------------------------------------------------------------------

$header-search-dropdown__padding--large: $base-padding * 2;

$header-search-dropdown-categories-item__padding--large: $base-padding * 1.5 0;

$header-search-dropdown-products-wrapper__padding-bottom--large: $base-margin * 2;
$header-search-dropdown-products-wrapper__border-bottom--large: $border solid $pale-liliac;

$header-search-dropdown-products__margin-left--large: $base-margin * 6;

//-----------------------------------------------------------------------------
// Screen Large
// -----------------------------------------------------------------------------

$popular-search-dropdown-list-li__margin-right--large: $base-margin * 3;
$popular-search-dropdown-links__padding-bottom--large: 2rem;
$popular-search-dropdown-suggestion-item__border-bottom--large: $border * 2 solid rgba($black, 0.23);
$popular-search-dropdown-help-title__color--large: $black;
$popular-search-dropdown-help-list-li__padding-bottom--large: $base-padding * 0.5;

$add-to-basket__padding--large: 0.9rem 4.9rem;

$header-search-dropdown-categories-results-list-li__line-height--large: 1.57;

$clear-search__top--large: 2.7rem;
$clear-search__right--large: 0.5rem;

$header-search-dropdown__width--large: 104.3rem;
$header-search-dropdown__position--large: absolute;
$header-search-dropdown__z-index--large: 99;
$header-search-dropdown__top--large: 8rem;
$header-search-dropdown__left--large: 50%;
$header-search-dropdown__margin-left--large: -52rem;

$header-search-dropdown-title__margin-bottom--large: $base-margin * 1.5;
$header-search-dropdown-categories__flex--large: 0 0 19rem;
$header-search-dropdown-product__padding--large: 0 $base-padding;
$header-search-dropdown-product-tile__padding-bottom--large: $base-padding * 2;

$header-search-dropdown__width--between: 90rem;
$header-search-dropdown__left--between: 50%;
$header-search-dropdown__margin-left--between: -45rem;

$minicart-quantity__positon: relative;
$minicart-quantity__top: -0.6rem;
$minicart-quantity__left: -0.5rem;
$minicart-quantity__display: inline-block;
$minicart-quantity__width: 1.5rem;
$minicart-quantity__height: 1.5rem;
$minicart-quantity__background-color: $white;
$minicart-quantity__border-radius: 50%;
$minicart-quantity__color: $red;
$minicart-quantity__line-height: 1;
$minicart-quantity__text-align: center;
$minicart-quantity__font-weight: $bolder;

