// =============================================================================
//
//  Header Top Large Screens
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .header-top {
        height: $header-top__height--large;

        &::after {
            background-color: $header-top__background-color--large;
        }

        &-container {
            padding: 0;
        }

        &-icon-list {
            display: inline-block;
            margin-right: $header-top-icons__margin-right--large;
        }
    }
    
    .price-header {
        @include get-font('small');
    }
}
